import * as React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout2"
import SEO from "../components/seo"

const NotFoundPage = ({ intl }) => (
  <Layout>
    <SEO 
      lang={intl.locale} 
      title={`404: ${intl.formatMessage({ id: "title" })}`} 
      keywords={['helios, helios inuvio, helios erp, erp, helios orange inuvio, helios brno, erp systém, solutions, helios orange, asseco solutions, asseco']}/>
    <div className="container">
      <div style={{marginBottom: "6%", marginTop: "6%"}}>
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </div>
  </Layout>
)

export default injectIntl(NotFoundPage)
